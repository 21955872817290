import React from "react";

import * as Icon from "react-feather"
import {Compliance, IncidentManagement, Settings, Risk} from "../navigationMenus";

// {
//   id: "dashboard",
//   title: "Dashboard",
//   type: "item",
//   icon: <Icon.Home size={20}/>,
//   navLink: "/"
// },
const navigationConfig = [
  {
    id: "risk",
    title: "Risk",
    type: "collapse",
    icon: <Icon.AlertTriangle size={20}/>,
    children: Risk
  },
  {
    id: "incident",
    title: "Incident",
    type: "collapse",
    icon: <Icon.Zap size={20}/>,
    children: IncidentManagement
  },
  // {
  //   id: "compliance",
  //   title: "Compliance",
  //   type: "collapse",
  //   icon: <Icon.CheckCircle size={20}/>,
  //   children: Compliance
  // },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20}/>,
    permissions: ["admin", "editor"],
    children: Settings
  }
]

export default navigationConfig
