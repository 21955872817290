import React, {useContext} from "react"
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledDropdown
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import {history} from "../../../history"
import {AuthContext} from "../../../AuthContext";
import {CatchError, firstChars} from "../../../utility/General";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import moment from "moment";

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const {logout} = useContext(AuthContext);
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/user/account")}
      >
        <Icon.User size={14} className="mr-50"/>
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/user/messages")}
      >
        <Icon.Mail size={14} className="mr-50"/>
        <span className="align-middle">My Messages</span>
      </DropdownItem>

      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        onClick={logout}
      >
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}


class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      navbarSearch: false,
      langDropdown: false,
      suggestions: [],
      user: false,
      attempt: 0,
      loading: false,
      messages: [],
      showManual: false,
      unread: 0
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("Props", this.props)
  }

  componentDidMount() {
    setTimeout((() => {
      this._getUser();
      this._messages()
    }), 2000);

  }

  _getUser = async () => {

    await axios.get('./app/user')
      .then(res => {
        this.setState({user: res.data})
      })
      .catch(e => {
        // CatchError(e);
        if (this.state.attempt < 2) {
          this.setState({attempt: this.state.attempt + 1}, this._getUser)
        }
      })
  }

  _messages = () => {
    let url = 'app/mail/summary';
    this.setState({loading: true});
    axios.get(url)
      .then(res => this.setState({messages: res.data.messages, unread: res.data.unread}))
      .catch((e) => {
        if (e.message === '') {
        }
      })
      .finally(() => this.setState({loading: false}))
  }

  _read = (message) => {
    let url = 'app/global/account/messages/read';
    this.setState({openMessage: message});
    let params = {id: message.id}

    axios.get(url, {params})
      // .then(res => this.setState({messages: res.data}))
      .catch(CatchError)
      .finally(this._messages)
  }


  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({langDropdown: !this.state.langDropdown})

  render() {
    const {user, openMessage, loading, showManual, messages, unread} = this.state;
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">


        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" onClick={() => this._messages()} className="nav-link nav-link-label">

            <Icon.Mail size={21}/>

            {messages && unread > 0 && <Badge pill color="danger" className="badge-up">
              {unread}
            </Badge>}
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">
                  {!loading && messages && unread}{' '}
                  {loading && <Spinner size={'md'}
                  />}
                  New</h3>
                <span className="notification-title">Messages</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {messages && messages.map(message => <div
                className="d-flex justify-content-between">
                <Media onClick={() => history.push(`/user/messages`)} className="d-flex align-items-start">

                  <Media body>
                    {(!openMessage || openMessage.id !== message.id) &&
                    <Media heading className={`${(message.read === 'No' ? message.color : null)} media-heading`}
                           tag="h6">
                      {message.subject}
                    </Media>}


                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime={message.created_at}
                    >
                      {moment(message.created_at).fromNow()}
                    </time>
                  </small>
                </Media>
              </div>)}


            </PerfectScrollbar>

          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" onClick={() => this.setState({showManual: true})} className="nav-link nav-link-label">

            <Icon.HelpCircle size={21}/>

          </DropdownToggle>

        </UncontrolledDropdown>

        {user && <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" onClick={this._getUser} className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
                      <span className="user-name text-bold-600">
                      {user.name}
                      </span>
              <span className="user-status">{user.status}</span>
            </div>
            <span data-tour="user">
            <Avatar img={user.avatar} size={40} color={'primary'} content={firstChars(user.name)}/>
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>}

        <Modal
          isOpen={showManual}
          toggle={() => this.setState({showManual: !showManual})}
          className="modal-dialog-centered modal-xl"
        >
          <ModalHeader
            toggle={() => this.setState({showManual: !showManual})}
          >
            User Manual
          </ModalHeader>
          <ModalBody>
            <iframe width="100%" height="600px"  src="https://rms-risk.com/manual.html"/>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => this.setState({showManual: false})}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>


        <Modal
          isOpen={false}
          toggle={() => this.setState({openMessage: false})}
          className="modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => this.setState({openMessage: false})}
          >
            {openMessage && openMessage.subject}
          </ModalHeader>
          <ModalBody>
            {openMessage && <div
              dangerouslySetInnerHTML={{
                __html: openMessage.body
              }}></div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setState({openMessage: false})}>
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </ul>
    )
  }
}

export default NavbarUser

/*
*
*


* */
