import * as Icon from "react-feather";
import React from "react";

const Risk = [
  {
    id: "risk-dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/"
  },
  // {
  //   id: "registers",
  //   title: "Registers",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   navLink: "/risk/register"
  // },
  // {
  //   id: "categories",
  //   title: "Categories",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   navLink: "/risk/category"
  // },
  {
    id: "risk-wizard",
    title: "Create New Risk",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/risk/wizard"
  },
  {
    id: "risk-all",
    title: "Risk Register",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/all"
  },
  {
    id: "controls",
    title: "Controls",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/control"
  },
  {
    id: "actionPlans",
    title: "Action Plans",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/action/plan"
  },
  // {
  //   id: "control-activity",
  //   title: "Controls Activities",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   navLink: "/risk/control/activity"
  // },
  {
    id: "risk-logs",
    title: "Audit Trail",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/risk/activity"
  },
  {
    id: "risk-report",
    title: "Reports",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/report"
  }
];
const Compliance = [
  {
    id: "compliance-dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance"
  },
  {
    id: "compliance-registers",
    title: "Registers",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/register/:id"
  },
  {
    id: "compliance-requirements",
    title: "Requirements",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/requirement"
  },
  {
    id: "authorities",
    title: "Authorities",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/authority"
  },
  {
    id: "compliance-logs",
    title: "Activity",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/activity"
  },
  {
    id: "obligations",
    title: "Obligations",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/obligation"
  },
];
const IncidentManagement = [
  {
    id: "incident-dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/incident"
  },  {
    id: "categories",
    title: "Categories ",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/incident/categories"
  }, {
    id: "incident-all",
    title: "All Incidents ",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/incident/all"
  },
  {
    id: "incident-actions",
    title: "Actions ",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/incident/actions"
  },
  {
    id: "incident-logs",
    title: "Activity",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/incident/activity"
  },
  // {
  //   id: "incident-report",
  //   title: "Report ",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/incident/report"
  // },
];
const Settings = [
  {
    id: "user",
    title: "User",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/user"
  },
  {
    id: "roles",
    title: "Roles",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/role"
  },
  {
    id: "Roles-Matrix",
    title: "Roles Matrix",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/roles"
  },
  // {
  //   id: "source-repository",
  //   title: "Repository",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/repository"
  // },
  {
    id: "functions",
    title: "Functions",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/department"
  },
  {
    id: "categories",
    title: "Categories",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    navLink: "/risk/category"
  },
  // {
  //   id: "integrations",
  //   title: "Integrations",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/settings/integration"
  // },
  {
    id: "logs",
    title: "Logs",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/logs"
  }
];

export {Risk, Compliance, IncidentManagement, Settings}
