import React, {useContext, useState, useEffect} from 'react';
import axios from "axios";
import cookie from "react-cookies";
import {toast} from "react-toastify";
import {CatchError} from "./utility/General";


export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(false);

  const logout = () => {
    cookie.save('permissions', false, {path: '/'});
    cookie.save('AuthToken', false, {path: '/'});
    cookie.save('auth', false, {path: '/'});
    cookie.save('loggedIn', false, {path: '/'});
    setUser(false);

    axios.defaults.headers.common = null;
  }

  const checkUser = () => {
    // if (cookie.load('loggedIn')) return true;
    if (cookie.load('loggedIn')) {
      setUser(cookie.load('auth'))
      axios.defaults.headers.common = cookie.load('AuthToken')
    }
    // setCheckingAuth(true)
    axios.get('/app/user').then(res => {
      setUser(res.data);
      cookie.save('permissions', res.data.permissions, {path: '/'})
    }).catch((e) => {
      setUser(false);
      if (e.status == 401) {
        logout()
      }
    }).finally(() => setCheckingAuth(false))
  }

  const login = async (email, password, remember) => {
    let url = 'auth/login';
    let data = {email, password, remember}
    toast.success("Authenticating");
    await axios.post(url, data).then(async (res) => {
      toast.info("Authentication successful");
      await cookie.save('permissions', res.data.permissions, {path: '/'})
      await cookie.save('auth', res.data.user, {path: '/'})
      await cookie.save('AuthToken', {'Authorization': `Bearer ${res.data.token}`}, {path: '/'})
      axios.defaults.headers.common = cookie.load('AuthToken')
      await setUser(res.data);
      await cookie.save('loggedIn', true, {path: '/'});
    }).catch(e => toast.warn(e.message))
      .finally(() => {
        // if (user) window.location.reload();
      })
  }

  useEffect(() => {

  }, []);
  // checkUser();
  return <AuthContext.Provider
    value={{checkUser, user, setUser, login, checkingAuth, logout}}>{children}  </AuthContext.Provider>
}
