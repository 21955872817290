import cookie from "react-cookies";
import axios from "axios";

let loading = false;
const refreshPermissions = async (lookingFor) => {

  if (loading) return false;
  loading = true;
  let url = 'app/user/permissions';
  await axios.get(url)
    .then(res => cookie.save('permissions', res.data, {path: '/'})).finally(() => {
      loading = false
    })
}

const Can = (permission, mine) => {
  // return true;
  // if(mine) return true;
  // console.log(permission);
  let permissions = cookie.load('permissions');
  let refresh = false;
  let allow = false;
  if (typeof permission == 'object') {
    // console.clear();
    permission.map(p => {
      if (typeof permissions[p] == 'undefined') refresh = true;
      if (permissions[p]) allow = true;
      // console.log(allow, p)
    })
  } else {
    if (typeof permissions[permission] == 'undefined') refresh = true;
    if (permissions[permission]) allow = true;
  }
  if (refresh) refreshPermissions(permission).finally();
  return allow;

}
export {Can, refreshPermissions}
